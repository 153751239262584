const tpl = new Map();

tpl.set('addAlias', (aliasesUrl, i18n) => `
    <div id="add-alias" class="alias">
        <a
            class="btn btn-link link-opacity-50-hover ms-1 ps-2 py-2 px-0 w-100 text-start text-decoration-none"
            href="${aliasesUrl}"
        >
            <i class="ico ipi-plus me-1"></i>
            ${i18n}
        </a>
    </div>
`);

export default tpl;
