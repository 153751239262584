import templates from './templates';

const renderAliases = async ({args, container}) => {
    const {aliasesI18n, aliases, aliasesUrl} = args;

    if (aliases.length > 0) {
        return;
    }

    container.innerHTML = templates.get('addAlias')(aliasesUrl, aliasesI18n);
};

export default (obj, INBOX) => {
    const offcanvasEl = document.querySelector('#side-menu');
    if (!offcanvasEl) {
        return;
    }

    offcanvasEl.addEventListener('show.bs.offcanvas', () => {
        const {aliasContainerId, ...args} = obj;
        const container = document.getElementById(aliasContainerId);

        if (!container) return;

        renderAliases({args, container});
    });
};
